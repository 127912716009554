<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="changeHead(index)"
          class="li"
          :class="{ active: index == headIndex }"
          v-for="(item, index) in headList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--comm-main start-->
    <div class="comm-main" v-if="headIndex == 0 || headIndex == 1">
      <!--comm-bd start-->
      <div class="comm-bd">
        <!--agree-head start-->
        <div class="agree-head">
          <div class="key_page_select">
            <div class="select_item">
              <div class="label">时间:</div>
              <div class="select">
                <el-date-picker
                  @change="init"
                  v-model="ruleForm.date_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <template v-if="headIndex == 1">
              <div class="select_item">
                <div class="label">区域:</div>
                <div class="select">
                  <el-cascader
                    size="large"
                    clearable
                    :options="regionList"
                    v-model="areaList"
                    placeholder="请选择所在区域"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
                </div>
              </div>
              <div class="select_item">
                <div class="label">门店:</div>
                <div class="select">
                  <el-select
                    @change="init"
                    clearable
                    v-model="ruleForm.dian_id"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.dian_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">职位:</div>
                <div class="select">
                  <el-select
                    @change="init"
                    clearable
                    v-model="ruleForm.position"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in positionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">姓名:</div>
                <div class="select">
                  <!-- <el-input
                    @input="init"
                    v-model="ruleForm.name"
                    placeholder="请输入"
                  ></el-input> -->
                  <el-select filterable
                    remote
                    :remote-method="getxius" v-model="ruleForm.name" placeholder="请选择姓名">
                    <el-option
                      v-for="item in userList"
                      :key="item.user_id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--agree-head end-->
        <!--affairs-content start-->
        <div class="affairs-content">
          <table class="affairs-table" v-if="headIndex == 0">
            <tr>
              <th>时间</th>
              <th>职位</th>
              <th>工号</th>
              <th>上班时间</th>
              <th>状态</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.date_time }} {{ item.weekday }}</td>
              <td>{{ getPosition(item.position) }}</td>
              <td>{{ item.job_number }}</td>
              <td>{{ item.createtime }}</td>
              <td :style="{'color':item.status == 1 ? '' : item.status == 2 ? '#FF5151' : '#FF9600'}">
                {{
                  item.status == 1 ? "正常" : item.status == 2 ? "迟到" : "请假"
                }}
              </td>
            </tr>
          </table>
          <table class="affairs-table" v-else>
            <tr>
              <th>时间</th>
              <th>区域</th>
              <th>门店</th>
              <th>职位</th>
              <th>工号</th>
              <th>上班时间</th>
              <th>状态</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.date_time }} {{ item.weekday }}</td>
              <td>{{ item.area }}</td>
              <td>{{ item.dian_name }}</td>
              <td>{{ getPosition(item.position) }}</td>
              <td>{{ item.job_number }}</td>
              <td>{{ item.createtime }}</td>
              <td :style="{'color':item.status == 1 ? '' : item.status == 2 ? '#FF5151' : '#FF9600'}">
                {{
                  item.status == 1 ? "正常" : item.status == 2 ? "迟到" : "请假"
                }}
              </td>
            </tr>
          </table>
          <div class="paging" v-if="list.length">
            <el-pagination
              background
              @current-change="changePage"
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!--affairs-content end-->
      </div>
      <!--comm-bd end-->
    </div>
    <!--comm-main end-->

    <!--rate_page_head end-->
    <div class="affairs-main" v-if="headIndex == 2 || headIndex == 3">
      <!--affairs-content start-->
      <div class="affairs-content">
        <el-form
          :model="ruleForm"
          class="form"
          label-width="100px"
          v-if="headIndex == 2"
        >
          <div class="affairs-add">
            <div class="" v-for="(item, index) in payment" :key="index">
              
              <div class="affairs-add-head flex">
                <div class="add-tit">报销{{ index + 1 }}</div>
                <el-button v-if="index == 0" @click="addPayMent">添加报销</el-button>
              </div>
              <!--add-item start-->
              <div class="add-item">
                <el-form-item label="报销金额:">
                  <el-input placeholder="请输入（元）" v-model="item.price"></el-input>
                </el-form-item>
              </div>
              <!--add-item end-->
              <!--add-item start-->
              <div class="add-item">
                <el-form-item label="报销内容:">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="item.content"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <!--add-item end-->
              <!--add-item start-->
              <div class="add-item">
                <el-form-item label="图片:">
                  <div class="upload">
                    <input type="file" @input="upload($event,index)">
                    <img v-if="item.image" :src="item.image" class="avatar-up" />
                    <img v-else class="avatar-up" src="../../assets/image/personage/add.png" /> 
                  </div>
                  
                </el-form-item>
              </div>
            </div>

            <!--add-item end-->
            <div class="add-tit">合计</div>
            <!--add-item start-->
            <div class="add-item">
              <el-form-item label="合计:">
                <el-input disabled placeholder="请输入" v-model="apply.total_price"></el-input>
              </el-form-item>
            </div>
            <!--add-item end-->
          </div>
        </el-form>
        <el-form
          class="form"
          ref="leave"
          :rules="rules"
          :model="leave"
          label-width="100px"
          v-else
        >
          <div class="affairs-add">
            <!--add-item start-->
            <div class="add-item">
              <el-form-item label="开始时间:" prop="start_time">
                <el-date-picker
                  @change="changeStart"
                  v-model="leave.start_time"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择开始时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <!--add-item end-->
            <!--add-item start-->
            <div class="add-item">
              <el-form-item label="结束时间:" prop="end_time">
                <el-date-picker
                  :disabled="!leave.start_time"
                  v-model="leave.end_time"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择结束时间"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <!--add-item end-->
            <!--add-item start-->
            <div class="add-item">
              <el-form-item label="请假原因:" prop="remark">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入请假原因"
                  v-model="leave.remark"
                >
                </el-input>
              </el-form-item>
            </div>
            <!--add-item end-->
          </div>
        </el-form>

        <div class="add-item-bot">
          <el-button class="add-item-btn" v-if="headIndex == 3" @click="swbmitLeave('leave')">提交</el-button>
          <el-button class="add-item-btn" v-else @click="reimbursement('apply')">提交</el-button>
        </div>
      </div>
      <!--affairs-content end-->
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate:(time)=> {
          return time.getTime() <  new Date(this.leave.start_time).getTime();
        }
      },
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      headIndex: 0, //
      regionList: regionData,
      CodeToText: CodeToText,
      areaList: [], //区域列表
      ruleForm: {
        role_type: "1", //身份：1 我的；2 他人
        date_time: "", //时间 格式：2021-02-26
        dian_id: "", //门店id；传id
        position: "", //职位；传：1=区域总监2=店长3=经理4=经纪人
        name: "", //名称
        area: "", //区域；例如：中原区
        page: 1,
      },
      leave: {
        start_time: "", //
        end_time: "",
        remark: "", //
      },
      rules: {
        start_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入请假原因", trigger: "change" },
        ],
      },
      headList: ["我的考勤", "团队考勤", "报销", "请假申请"],
      apply: {
        content: "",
        total_price: "",
      }, //报销申请
      payment: [
        {
          price: "",
          content: "",
          image: "",
        },
      ],
      shopList:[],//门店列表
      userList:[],//用户列表
    };
  },
  watch:{
    payment:{
      handler (newName) {
        let price = 0
        newName.forEach(element => {
          price+=Number(element.price)
        });
        this.apply.total_price = price
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getxius(e) {
      this.$axios.userList({ position: 4,name:e }).then((res) => {
        this.userList = res.data;
      });
    },
    changePage (e) {
      this.ruleForm.page = e;
      this.getCheckList();
    },
    changeStart () {
      this.leave.end_time = ''
    },
    upload (e,index) {
      this.$axios.uploadFile(e.target.files[0]).then(res=>{
        this.payment[index].image = res.fullurl
      })
    },
    //添加报销
    addPayMent () {
      this.payment.push({
        price: "",
        content: "",
        image: "",
      })
    },//
    //提交报销
    reimbursement() {
      if (this.payment.every(item=>!item.price || !item.image || !item.content)) return this.$message.error('请填写必填项');
      this.apply.content = JSON.stringify(this.payment)

      this.$axios.subBaoXiao(this.apply).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.apply.content = ''
        this.apply.total_price = ''
        this.headIndex = 0
        this.payment = [{price: "",content: "",image: ""}]
      });
    },
    swbmitLeave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.subAskLeave(this.leave).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.leave.start_time = ''
            this.leave.end_time = ''
            this.leave.remark = ''
            this.headIndex = 0
          });
        } else {
          return false;
        }
      });
    },
    //切换头部
    changeHead(index) {
      this.headIndex = index;
      if (index == 0 || index == 1) {
        this.ruleForm.role_type = index + 1;
        this.init();
      }
    },
    //初始化
    init() {
      this.ruleForm.page = 1;
      this.list = [];
      this.getCheckList();
    },
    //获取考勤记录
    getCheckList() {
      this.$axios.checkInLog(this.ruleForm).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    getmenDian () {
      this.$axios.menDian().then(res=>{
        this.shopList = res.data
      })
    },
    //选择区域
    handleChangeCity () {
      let data = []
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      this.ruleForm.area = data[2]
      this.init()
    },
  },
  created() {
    this.getxius('');
    this.getmenDian()
    this.getCheckList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  margin-bottom: 10px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.comm-main {
  background: #fff;
  padding: 25px 30px;
  .comm-hd {
    width: 690px;
    height: 210px;
    background: url("../../assets/image/personage/money.png") no-repeat center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    .c {
      font-size: 42px;
      margin-top: 15px;
    }
    .t {
      font-size: 20px;
    }
  }
  .comm-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 18px;
      padding-left: 10px;
      position: relative;
    }
    .name:before {
      display: block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      content: "";
      background: #3273f6;
      border-radius: 2px;
    }
    .comm-select {
      /deep/ .el-input {
        width: 160px;
      }
      /deep/ .el-input__inner {
        border: 1px solid #ccc;
        border-radius: 0;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
  .comm-list {
    .comm-item {
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 15px 0px;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        width: calc(100% - 150px);
        .icon {
          width: 44px;
          height: 44px;
        }
        .txt {
          margin-left: 20px;
          .t {
            font-size: 16px;
            line-height: 1.6;
          }
          .b {
            display: flex;
            font-size: 14px;
            line-height: 1.6;
            margin: 5px -20px;
          }
          .b-s {
            line-height: 1.6;
            margin: 0 20px;
          }
        }
      }
      .d {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.6;
      }
      .item-r {
        width: 150px;
        text-align: right;
        .add-num {
          font-size: 24px;
          color: #ff2121;
        }
      }
    }
  }
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-date-editor.el-input {
    width: 200px;
  }
}
.affairs-content {
  margin: 20px 10px;
}
.affairs-table {
  width: 100%;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}
.affairs-table th {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-top: none;
  border-bottom: none;
  padding: 15px 10px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
.affairs-table td {
  padding: 15px 10px;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  font-size: 14px;
}
.affairs-table-btn {
  display: flex;
  .btn {
    padding: 0px;
    background: none;
    outline: none;
    margin: 5px;
    border: none;
  }
  .color-orange {
    color: #ff9600;
  }
  .color-red {
    color: #ff5151;
  }
}
.affairs-table tr td {
  background: #f7fcff;
}
.affairs-table tr:nth-child(2n) td {
  background: #fff;
}
.affairs-main {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 20px;
}
.affairs-add-btn {
  width: 100px;
  height: 40px;
  padding: 0px;
  border-radius: 4px;
  background: #3273f6;
  text-align: center;
  color: #fff;
  line-height: 40px;
  font-size: 16px;
  position: absolute;
  top: 30px;
  right: 0px;
}
.affairs-content {
  padding: 0 20px;
}
.affairs-add {
  padding: 0 10px;
  .add-tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    margin: 25px 0px;
  }
  .add-tit:before {
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    width: 4px;
    height: 20px;
    background: #3273f6;
    display: block;
    content: "";
  }
  .add-item {
    display: flex;
    margin: 0 20px;
  }
  .item-li {
    width: 50%;
  }
  /deep/ .el-input {
    width: 400px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px;
  }
  /deep/ .el-input__inner {
    font-size: 16px;
    border-radius: 0;
    border: 1px solid #ccc;
  }
  /deep/ .el-textarea {
    width: 400px;
  }
  .add-item-icon {
    margin: 8px 10px;
  }
  .add-item-icon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  /deep/ .el-button {
    width: 100px;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
    background: #3273F6;
    border-radius: 4px;
  }
}
.portrait-img img {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}
.avatar-uploader .avatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}
.upload {
  width: 150px;
  height: 150px;
  display: block;
  cursor: pointer;
  position: relative;
  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    display: block;
  }
}
.add-item-bot {
  margin: 35px 0px;
  display: flex;
  justify-content: center;
}
.add-item-btn {
  width: 680px;
  height: 56px;
  background: #3273f6;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
</style>
